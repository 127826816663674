.block {
    position: relative;
    background-color: white;
    padding: 20px 30px;
    border-radius: 5px;
  }

  .field {
    color: black;
    font-size: medium;
    margin-bottom: 15px;
  }

  .errFieldMsg {
    font-size: small;
    color: var(--branded-error-red);
  }

  .assessmentCheckBox{
    background: var(--branded-purple);
}
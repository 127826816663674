.feedback-message { 
  font-size: 13px;
  color: #DC3545;
}

.feedback-icon {
  color: #DC3545;
  margin-right: 5px;
}

.feedback-container {
  margin-top: 5px;
}
@import "~antd/dist/antd.css";

.app {
  background-color: #f9f9f9;
  min-height: 100vh;
}

.top-nav {
  background-color: white;
  border-bottom: 1px solid #dee2e6;
  box-shadow: 0 8px 6px -6px #dee2e6;
}

.footer {
  margin-top: auto;
  width: 100%;
  height: 60px;
  border-top: 1px solid #dee2e6;
  padding: 17px 20px;
  color: #6c757d;
  background-color: whitesmoke;
}

.sub-header {
  text-align: center;
  margin-top: -20px;
  margin-bottom: 40px;
}

.description-text {
  font-size: 14px;
}

.option-button-lg {
  min-height: 100px;
}

.option-button-md {
  min-height: 65px;
}

.intake-form {
  border: 1px solid lightgray;
  border-radius: 3px;
  padding: 20px;
  background-color: white;
}

.checkbox-container {
  border: 1px solid gainsboro;
  border-radius: 5px;
  padding: 6px 10px;
  font-size: 14px;
}

.placesAutoComplete {
  width: 100%;
  font-size: 15px;
  font-weight: 400;
  height: 38px;
  border: 1px solid #ced4da;
  border-radius: 3px;
  padding-left: 8px;
  padding-right: 8px;
  color: #4a5055;
}

.check-icon {
  margin-right: 15px;
  font-size: 16px;
  color: whitesmoke;
  font-weight: bold;
}

.optional-header {
  background-color: gainsboro;
  border: 1px solid #ced4da;
  border-radius: 3px;
  padding: 10px 15px;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 14px;
}

.form-label {
  font-weight: 500;
}

.tab-container {
  padding: 20px;
}

.tab-page {
  background-color: white;
  border: 1px solid gainsboro;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.selectDisable {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.align-center {
  text-align: center;
  vertical-align: middle !important;
}
.left-indent {
  text-indent: -1rem;
  margin-left: 1rem;
}
.left-indent-lg {
  text-indent: -2rem;
  margin-left: 2rem;
}
.form-check-input {
  height: 1rem !important;
  width: 1rem !important;
}
.remove-padding-right {
  padding-right: 0 !important;
}
.remove-padding-left {
  padding-left: 0 !important;
}
.invalid {
  border: 1px solid #dc3545;
  padding: 0.5rem;
  border-radius: 5px;
}
.status {
  float: right;
}
.pending {
  background-color: #b184e0 !important;
  border-color: #b184e0 !important;
}
.declined {
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
}
.completed {
  background-color: #28a745 !important;
  border-color: #28a745 !important;
}
.text-position {
  text-align: center;
}
.left-indent {
  text-indent: -1em;
}

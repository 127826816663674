.form{
    height: 100%;
}
.formLayout{
    display: grid;
    grid-template-areas: 'body' 'footer';
    width:100%;
    height: 100%;
    grid-gap: 0px;
    padding-right: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
}
.formLayoutBody{
    grid-area: body;
    overflow: auto;
    padding: 10px;
    background: #F3EFF8;
    border-radius: 10px;
}
.formLayoutFooter{
    grid-area: footer;
    padding: 10px;
    background: #F3EFF8;
}
.buttonContainer{
    display: flex;
    flex-direction: row-reverse;
    margin-top: 10px;
}
.assessmentButton{
    border-radius: 28px;
    padding: 8px 16px;
    font-weight: 600;
    font-size: 16px;
    margin-right: 10px;
}

.assessmentSaveButton{
    background: #883cae;
    color: white;
    border: none;
    border-radius: 30px;
}

.assessmentCancelButton{
    background: transparent;
    color: #883cae;
    /* border: none; */
    border-radius: 30px;
    border-color: #883cae;
}

.assessmentCancelButton:hover{
    background: #883cae;
    color: white;
    border-radius: 30px;
    border-color: #883cae;
}


.questionLayout{
    display: grid;
    grid-template-areas:
      'name'
      'body';
    width:100%;
    column-gap: 10px;   
    border: 3px solid #E7E0F1;
    border-radius: 16px;
    padding: 10px;
    margin-bottom: 5px;
    background: #FFFFFF;
    
}

.questionLayoutWithScore{
    display: grid;
    grid-template-areas:
      'name score'
      'body body';
      grid-template-columns: auto 90px;
    width:100%;
    column-gap: 10px;   
    border: 3px solid #E7E0F1;
    border-radius: 16px;
    padding: 10px;
    margin-bottom: 5px;
    background: #FFFFFF; 
}

.questionLayoutName{
    grid-area: name;
    position: relative;
    margin-bottom: 10px;
}

.assessmentRequired{
    color: red;
}

.questionLayoutScore{
    grid-area: score;
    position: relative;
    margin-bottom: 10px;
    text-align: end;
}

.questionLayoutBody{
    grid-area: body;
    position: relative;
    padding-left: 30px;
}

.horizontalQuestion :global(.radioGroup){
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
}

.errFieldMsg {
    font-size: small;
    color: #b30000;
}

.assessmentGroupContainer{
    margin-bottom: 10px;
}

.assessmentGroupContainer :global(.p-panel-header){
     background: #EEDEF6; 
}

.assessmentGroupContainerHorizontal{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
}

.assessmentGroupContainerHorizontal .questionLayoutWithScore{
    width: 49%;
}

.assessmentGroupContainerHorizontal .questionLayout{
    width: 49%;
}

.assessmentMatrixContainer{
    margin-bottom: 10px;
}

.assessmentMatrixContainer :global(.p-panel-header){
    background: #C39DD5;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.p-dialog-content{
    /* background-color: red !important; */
    padding-right: 0rem;
    padding-left: 0.75rem;
    padding-top: 0.75rem;
    padding-bottom: 0rem;
}